import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash.get';

export const PrivateRoute = (({ component: Component, render = () => null, ...rest }) => {
  const history = useHistory();
  const userInfo = useSelector((state) => get(state, ['auth', 'userInfo']));

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userInfo) {
          return (
            Component ? <Component {...props} /> : render(props)
          );
        }
        if (window.confirm('로그인이 필요합니다.')) {
          history.push('/login');
          return null;
        }
        return <Redirect to="/" />;
      }}
    />
  );
});

PrivateRoute.propTypes = Route.propTypes;

export const PublicRoute = ({ component: Component, render = () => null, ...rest }) => {
  const userInfo = useSelector((state) => get(state, ['auth', 'userInfo']));

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userInfo) {
          const { location, history } = props;
          const { state } = location;
          // @ts-ignore
          if (state && state.referrer === 'comeback') history.goBack();
          // @ts-ignore
          else if (state && state.referrer) history.replace(state.referrer);
          else history.push('/', { referrer: 'public' });
          return null;
        }
        if (Component) return <Component {...props} />;
        return render(props);
      }}
    />
  );
};

PublicRoute.propTypes = Route.propTypes;
