// export default (function () {
//   try {
//     const st = localStorage || {};
//     return {
//       setItem: (key, object) => {
//         st[key] = typeof object === 'string' ? object : JSON.stringify(object);
//       },
//       getItem: (key, parse = true) => {
//         if (!st[key]) {
//           return null;
//         }
//         const value = st[key];
//
//         try {
//           const parsed = parse ? JSON.parse(value) : value;
//           return parsed;
//         } catch (e) {
//           return value;
//         }
//       },
//       removeItem: (key) => {
//         if (localStorage) {
//           return localStorage.removeItem(key);
//         }
//         return delete st[key];
//       },
//     };
//   } catch (err) {
//     console.warn(err);
//     setTimeout(() => alert('Cookie disabled'), 1000);
//     return {
//       setItem: (key, object) => '',
//       getItem: (key) => '',
//       removeItem: (key) => '',
//     };
//   }
// }());
// 로컬 스토리지에 JSON 형태로 저장 / 불러오기 / 삭제 헬퍼
const storage = {
  set: (key, object) => {
    if (!localStorage) return;
    localStorage[key] = (typeof object) === 'string' ? object : JSON.stringify(object);
  },
  get: (key) => {
    if (!localStorage) return null;

    if (!localStorage[key]) {
      return null;
    }

    try {
      const parsed = JSON.parse(localStorage[key]);
      return parsed;
    } catch (e) {
      return localStorage[key];
    }
  },
  remove: (key) => {
    if (!localStorage) return null;

    if (localStorage[key]) {
      localStorage.removeItem(key);
    }
    return null;
  },
};

export default storage;
