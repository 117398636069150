import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { flexCenter } from '../../../utils/mixins';

const StyledSpinner = styled.div`
  width: 100%;
  height: 90vh;
  ${flexCenter}
  @media (min-width: 640px) {
    height: 60vh;
  }
`;
const antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
const Spinner = () => (
  <StyledSpinner>
    <Spin indicator={antIcon} />
  </StyledSpinner>
);

export default Spinner;
