import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { pender } from 'redux-pender';
import * as publicApi from '../../network/api/publicApi';
import storage from '../../lib/storage';

// Action Types
const GET_CELLS_INFO = 'neuron/GET_CELLS_INFO';
const REMOVE_CELLS = 'neuron/REMOVE_CELLS';
const SET_CELLS = 'neuron/SET_CELLS';
const SAVE_SELECTED_CELLS = 'neuron/SAVE_SELECTED_CELLS';
const APPEND_SELECTED_CELLS = 'neuron/APPEND_SELECTED_CELLS';

// Action Creator
export const getCellsInfo = createAction(GET_CELLS_INFO, publicApi.getCellInfo);
export const removeCells = createAction(REMOVE_CELLS);
export const setCells = createAction(SET_CELLS);
export const saveSelectedCells = createAction(SAVE_SELECTED_CELLS);
export const appendSelectedCells = createAction(APPEND_SELECTED_CELLS);

// Initial State
const initialState = {
  // 컬렉션 화면에서 사용하는 cell 상세 정보 리스트.
  cellsInfo: [],
  // '검색결과 -> 뉴런', '뉴런 -> 콜렉션', '콜렉션 -> 뉴런' 으로 이동할 때 전달하는 cell id 리스트.
  selectedCellIds: [],
  // 모든 히스토리를 남긴다.
  historyCellIds: [],
};

// cell 혹은 relationship 에서 id 중복 제거
// const distinct = (arr) => arr.reduce(
//   (acc, curr) => ((acc.some((item) => item.id === curr.id)) ? acc : [...acc, curr]), [],
// );

// Reducers
export default handleActions({
  [SAVE_SELECTED_CELLS]: (state, action) => produce(state, (draft) => {
    const LSCellIds = storage.get('historyCellIds');
    // 새로고침시 삭제되는 이유로 local storage를 사용한다.
    if (LSCellIds) {
      draft.historyCellIds = action.payload.concat(LSCellIds);
    } else {
      draft.historyCellIds = action.payload;
    }
    storage.set('historyCellIds', draft.historyCellIds);

    if (draft.historyCellIds?.length > 50) {
      draft.historyCellIds = draft.historyCellIds.slice(0, 50);
      storage.set('historyCellIds', draft.historyCellIds);
    }

    draft.selectedCellIds = storage.get('historyCellIds');
  }),
  [APPEND_SELECTED_CELLS]: (state, action) => produce(state, (draft) => {
    draft.selectedCellIds = state.selectedCellIds.concat(action.payload);
  }),
  ...pender({
    type: GET_CELLS_INFO,
    onSuccess: (state, action) => produce(state, (draft) => {
      const { cells } = action.payload.data;
      draft.cellsInfo = cells;
    }),
  }),
  [REMOVE_CELLS]: (state, { payload: cellId }) => ({
    ...state,
    cellsInfo: state.cellsInfo.filter((cell) => cell.id !== cellId),
    selectedCellIds: state.selectedCellIds.filter((cell) => cell !== cellId.toString()),
  }),
  [SET_CELLS]: (state, { payload: cells }) => produce(state, (draft) => {
    draft.cellsInfo = cells;
    draft.selectedCellIds = cells.map((x) => x.id.toString());
  }),
}, initialState);
