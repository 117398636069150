import ReactGA from 'react-ga4';
import { isProductionServer } from './variables';

/**
 * 현재 pathname. (이벤트 처리를 하지 않은 페이지를 확인 할 수 있도록 하는 용도)
 * 1. pathname 이 변할 때 마다 값을 저장.
 * 2. 현재 페이지에서 google analytics 이벤트를 전송하면, 값을 null 로 변경.
 * 3. page 전환했는데 unprocessedPathName 이 null 이 아니라면,
 * 이전 페이지에서 GoogleAnalytics 에 이벤트 처리를 하지 않은 것이니,
 * GA 에 해당 pathName 을 보고를 한다.
 * @type {string|null}
 */
let unprocessedPathName = null;

/**
 * GoogleAnalytics 에 이벤트 전송
 */
export const sendGoogleAnalytics = (event, prefix) => {
  const eventName = `${prefix || '[PAGE]'}${event}`;

  if (isProductionServer) {
    // firebase.analytics().logEvent(eventName);
    // ReactGA.set({ page: unprocessedPathName });
    // ReactGA.send('pageview');
    ReactGA.send({ hitType: 'pageview', page: eventName });
    console.log(`[PRODUCTION - GA] SEND EVENT! ${eventName}`);
  } else {
    // ReactGA.send('pageview');
    // ReactGA.send({ hitType: 'pageview', page: eventName });
    console.log(`[STAGING - GA] SEND EVENT! ${eventName}`);
  }

  unprocessedPathName = null;
};

/**
 * GoogleAnalytics 에 이벤트 전송하지 않음
 */
export const skipGoogleAnalytics = () => {
  unprocessedPathName = null;
};

/**
 * Page 가 바뀔 때 마다 (location.pathname 이 변할 때 마다 pathName 을 저장.
 * @param pathName
 */
export const onChangePathName = (pathName) => {
  if (unprocessedPathName) {
    sendGoogleAnalytics(`UNKNOWN(${unprocessedPathName})`);
  }
  unprocessedPathName = pathName;
};
