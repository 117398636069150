import axios from 'axios';
import { message } from 'antd';
import { nanoid } from 'nanoid';
import { saveMyUserInfo, deleteMyUserInfo } from '../store/reducers/auth';
import store from '../store';
import { API_HOST, isProductionServer } from './variables';
import storage from '../lib/storage';
import { getMyUserInfo } from '../network/api/privateApi';

export const API_URL_SIGN_UP = '/v1/auth/signup';
export const API_URL_LOGIN = '/v1/auth/login';
export const API_URL_VERIFY_EMAIL = '/v1/public/email/verify';
export const API_URL_START_WITH_KAKAO = '/v1/auth/social/kakao';
export const API_URL_START_WITH_APPLE = '/v1/auth/social/apple';
export const API_URL_START_WITH_GOOGLE = '/v1/auth/social/google';
export const API_URL_SEND_RESET_PASSWORD_EMAIL = '/v1/auth/send-reset-password-email';
export const API_URL_CHECK_CODE = '/v1/auth/check-reset-password-code';
export const API_URL_RESET_PASSWORD_WITH_CODE = '/v1/auth/reset-password';
export const API_URL_RESET_PASSWORD = '/v1/private/users/my/password';

const API_URL_REFRESH_TOKEN = '/v1/auth/refresh-tokens';

const KEY_ACCESS_TOKEN = 'access_token';
const KEY_REFRESH_TOKEN = 'refresh_token';

// Access Token 을 가져옵니다.
export function getAccessToken() {
  return localStorage.getItem(KEY_ACCESS_TOKEN);
}

// Refresh Token 을 가져옵니다.
export function getRefreshToken() {
  return localStorage.getItem(KEY_REFRESH_TOKEN);
}

export const setAuthHeader = (accessToken) => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const setAcceptLanguage = (userLanguage) => {
  axios.defaults.headers.common['Accept-Language'] = `${userLanguage}`;
};

export const setContentLanguage = (userLanguage) => {
  axios.defaults.headers.common['Content-Language'] = `${userLanguage}`;
};

export const setAnonymousHeader = () => {
  let anonymousCode = localStorage.getItem('anonymousCode');
  if (!anonymousCode) {
    anonymousCode = nanoid();
    localStorage.setItem('anonymousCode', anonymousCode);
  }
  axios.defaults.headers.common.Authorization = `Anonymous ${anonymousCode}`;
};

export function saveUserAndTokens(tokens) {
  const { accessToken, refreshToken } = tokens;
  localStorage.setItem(KEY_ACCESS_TOKEN, accessToken);
  localStorage.setItem(KEY_REFRESH_TOKEN, refreshToken);

  setAuthHeader(accessToken);

  getMyUserInfo()
    .then((res) => {
      console.log('[SUCCESS] getMyUserInfo: ');
      store.dispatch(saveMyUserInfo(res.data.userInfo));
    })
    .catch((err) => {
      console.log('[ERROR] getMyUserInfo: ', err.message);
    });
}

export function goToLoginPage() {
  // const history = useHistory();
  // history.push('/login');
  window.location.href = '/login';
}

export function deleteLoginUserInfo() {
  localStorage.removeItem(KEY_ACCESS_TOKEN);
  localStorage.removeItem(KEY_REFRESH_TOKEN);

  storage.set('showWelcomeMessage', 'false');
  storage.set('user', 'false');

  store.dispatch(deleteMyUserInfo());
}
export function logout() {
  deleteLoginUserInfo();

  // 홈 화면으로 이동.
  window.location.href = window.location.origin;
}

const isAuthApiUrl = (url) => {
  const result = url.split('/')[2] === 'auth';
  console.log(`[isAuthApi] result = ${result}, url = ${url}`);
  return result;
};

const isPaidApiUrl = (url) => {
  const result = url.split('/')[2] === 'paid';
  console.log(`[isPaidApi] result = ${result}, url = ${url}`);
  return result;
};

export const showInvalidSubscriptionError = () => {
  message.warn({
    content: '유료 회원만 이용할 수 있는 기능입니다.',
    key: 40301,
  });
};

export const checkSubscription = (userInfo, showMessage) => {
  if (isProductionServer) { // 프로덕션에서는 일단 무조건 통과시키도록 수정.
    return true;
  }

  if (!userInfo?.isValidUser) {
    if (showMessage) showInvalidSubscriptionError();
    return false;
  }
  return true;
};

export const axiosSettings = () => {
  axios.defaults.baseURL = API_HOST;
  axios.defaults.timeout = 100000;

  axios.interceptors.response.use(
    (res) => res.data,
    async (error) => {
      let finalError = error;

      // 401 에러가 발생했을 때, Refresh Token 을 사용하여 새로운 Access Token 을 발급하고, 요청을 재실행합니다.
      const originalRequest = error.config;
      const isAuthApi = isAuthApiUrl(originalRequest.url);
      if (!isAuthApi && error.response?.status === 401 && !originalRequest.isRetryCall) {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          originalRequest.isRetryCall = true;
          try {
            const response = await axios.post(API_URL_REFRESH_TOKEN, { refreshToken });
            saveUserAndTokens(response.data);
            originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
            return axios(originalRequest);
          } catch (error2) {
            finalError = error2;
          }
        }
      } else if (isPaidApiUrl(originalRequest.url) && error.response?.data?.errorCode === 40301) {
        showInvalidSubscriptionError();
      }

      if (!isAuthApi && finalError.response?.status === 401) {
        logout();
        // 필요하면 로그인 페이지로 이동
      }

      return Promise.reject(error);
    },
  );
};
