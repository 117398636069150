import styled from 'styled-components';

const Container = styled.div`
  background-color: #ffffff;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 35px;
  @media (min-width: 640px) {
    width: 640px;
    min-height: calc(100vh - 200px); 
    padding: 0 0 15px 0;
  }
`;

export default Container;
