import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { pender } from 'redux-pender';
import * as publicAPI from '../../network/api/publicApi';

// Action Types
const GET_COLLECTION_INFO = 'collection/GET_COLLECTION_INFO';
const UPDATE_CELLS_INFO = 'collection/UPDATE_CELLS_INFO';
const REMOVE_CELLS = 'collection/REMOVE_CELLS';
const SET_CELLS_INFO = 'collection/SET_CELLS_INFO';
const ADD_SELECTED_CELLS = 'collection/ADD_SELECTED_CELLS';
const GET_DAILY_ISSUE = 'collection/GET_DAILY_ISSUE';
const SEARCH_HOME_COLLECTIONS = 'collection/SEARCH_HOME_COLLECTIONS';
const GET_NEW_COLLECTIONS_BY_USER = 'collection/GET_NEW_COLLECTIONS_BY_USER';
const GET_BOOKS_BY_CATEGORY = 'collection/GET_BOOKS_BY_CATEGORY';
const GET_TODAY_COLLECTION = 'collection/GET_TODAY_COLLECTION';
const GET_HOT_COLLECTIONS = 'collection/GET_HOT_COLLECTIONS';

// Action Creator
export const getCollectionInfo = createAction(GET_COLLECTION_INFO, publicAPI.getCollection);
export const updateCellsInfo = createAction(UPDATE_CELLS_INFO, publicAPI.getCellInfo, (...meta) => meta);
export const removeCells = createAction(REMOVE_CELLS);
export const setCellsInfo = createAction(SET_CELLS_INFO);
export const addSelectedCells = createAction(ADD_SELECTED_CELLS);
export const getDailyIssue = createAction(GET_DAILY_ISSUE, publicAPI.getCollectionsByCategory, (...meta) => meta);
export const searchCollections = createAction(SEARCH_HOME_COLLECTIONS, publicAPI.searchCollections);
export const getTodayCollections = createAction(GET_TODAY_COLLECTION, publicAPI.getTodayCollections, (...meta) => meta);
export const getNewCollectionsByUser = createAction(GET_NEW_COLLECTIONS_BY_USER, publicAPI.getNewCollections, (...meta) => meta);
export const getBooksByCategory = createAction(GET_BOOKS_BY_CATEGORY, publicAPI.getCollectionsByCategoryAtOnce, (...meta) => meta);
export const getHotCollections = createAction(GET_HOT_COLLECTIONS, publicAPI.getCollectionsByCategory);

// Initial State
const initialState = {
  collections: {},
  searchResults: [],
  dailyIssue: [],
  historyCollectionList: [], // 홈 > 지식북 > 맞춤형추천
  newCollectionsByUser: [],
  booksByCategory: [],
  todayCollectionList: [],
  hotCollections: [],
};

// Reducers
export default handleActions(
  {
    ...pender({
      type: GET_COLLECTION_INFO,
      onSuccess: (state, action) => {
        const { collection } = action.payload.data;
        return produce(state, (draft) => {
          draft.collections[collection.id] = collection;
        });
      },
    }),
    ...pender({
      type: UPDATE_CELLS_INFO,
      onSuccess: (state, action) => produce(state, (draft) => {
        const { cells } = action.payload.data;
        const id = action.meta[2];
        draft.collections[id].cellsInfo = cells;
      }),
    }),
    [REMOVE_CELLS]: (state, action) => produce(state, (draft) => {
      const { collectionId, cellId } = action.payload;
      const cellsInfo = state.collections[collectionId].cellsInfo.filter((cell) => cell.id !== cellId);
      draft.collections[collectionId] = {
        id: collectionId,
        cellIds: cellsInfo.map((x) => x.id.toString()),
        cellsInfo,
      };
      return draft;
    }),
    [SET_CELLS_INFO]: (state, { payload: { cellsInfo, collectionId } }) => produce(state, (draft) => {
      draft.collections[collectionId] = {
        ...state.collections[collectionId],
        cellIds: cellsInfo.map((x) => x.id.toString()),
        cellsInfo,
      };
      return draft;
    }),
    [ADD_SELECTED_CELLS]: (state, action) => produce(state, (draft) => {
      const { id, cellIds } = action.payload;

      // 기존 셀을 삭제하고, 새로운 셀로 대체하려면 아래 코드로 실행.
      draft.collections[id].cellIds = cellIds;

      // 기존 셀을 삭제하지 않고, 추가만 하려면 아래 코드로 실행.
      // for (const cellId of cellIds) {
      //   if (!draft.collections[id].cellIds.some((item) => item === cellId)) {
      //     draft.collections[id].cellIds.push(cellId);
      //   }
      // }
      return draft;
    }),
    ...pender({
      type: GET_DAILY_ISSUE,
      onSuccess: (state, action) => produce(state, (draft) => {
        draft.dailyIssue = action.payload.data.cells;
      }),
    }),
    ...pender({
      type: SEARCH_HOME_COLLECTIONS,
      onSuccess: (state, action) => produce(state, (draft) => {
        draft.searchResults = action.payload.data.collections;
      }),
    }),
    ...pender({
      type: GET_NEW_COLLECTIONS_BY_USER,
      onSuccess: (state, action) => produce(state, (draft) => {
        draft.newCollectionsByUser = action.payload.data.collections;
      }),
    }),
    // ...pender({
    //   type: GET_BOOKS_BY_CATEGORY,
    //   onSuccess: (state, action) => produce(state, (draft) => {
    //     const category = action.meta[0];
    //     draft.booksByCategory[category] = action.payload.data.cells;
    //   }),
    // }),
    ...pender({
      type: GET_BOOKS_BY_CATEGORY,
      onSuccess: (state, action) => produce(state, (draft) => {
        const category = action.meta[0];
        const newData = action.payload.data.cells[category];
        if (category && newData) {
          draft.booksByCategory = {
            ...draft.booksByCategory,
            [category]: newData,
          };
        } else {
          draft.booksByCategory = action.payload.data.cells;
        }
      }),
    }),
    ...pender({
      type: GET_TODAY_COLLECTION,
      onSuccess: (state, action) => produce(state, (draft) => {
        draft.todayCollectionList = action.payload.data.collections;
      }),
    }),
    ...pender({
      type: GET_HOT_COLLECTIONS,
      onSuccess: (state, action) => produce(state, (draft) => {
        draft.hotCollections = action.payload.data.cells.sort(() => Math.random() - 0.5);
      }),
    }),
  },

  initialState,
);
