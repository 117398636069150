import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { pender } from 'redux-pender';
import storage from '../../lib/storage';
import * as privateAPI from '../../network/api/privateApi';

// Action Types
const SAVE_MY_USER_INFO = 'auth/SAVE_MY_USER_INFO';
const UPDATE_USER_MEMBERSHIP = 'auth/UPDATE_USER_MEMBERSHIP';
const DELETE_MY_USER_INFO = 'auth/DELETE_MY_USER_INFO';
const GET_NOTICE = 'auth/GET_NOTICE';
const DELETE_NOTICE = 'auth/DELETE_NOTICE';
const SET_LAST_LOACTION = 'auth/SET_LAST_LOACTION';
const SET_USER_LANGUAGE = 'auth/SET_USER_LANGUAGE';

// Action Creator
export const saveMyUserInfo = createAction(SAVE_MY_USER_INFO);
export const updateUserMembership = createAction(UPDATE_USER_MEMBERSHIP);
export const deleteMyUserInfo = createAction(DELETE_MY_USER_INFO);
export const getNotice = createAction(GET_NOTICE, privateAPI.getNotice);
export const deleteNotice = createAction(DELETE_NOTICE, privateAPI.deleteNotice, (...meta) => meta);
export const setLastLocation = createAction(SET_LAST_LOACTION, (...meta) => meta);
export const setUserLanguage = createAction(SET_USER_LANGUAGE);

// Initial State
const initialState = {
  userInfoLoaded: false, // 처음 로딩해서 내 정보를 불러왔는지 여부. true: 사용자가 로그인했을수도 아닐수도있음. false: 로그인여부를 알 수 없음.
  userInfo: undefined, // userInfo 가 있으면 로그인 한 상태. 없으면 그렇지 않음.
  isAuthenticated: undefined,
  lastLocation: '',
  userLanguage: storage.get('userLanguage') || navigator.language.split('-')[0],
};

// Reducers
export default handleActions({
  [SAVE_MY_USER_INFO]: (state, action) => produce(state, (draft) => {
    draft.userInfo = action.payload;
    draft.userInfoLoaded = true;
  }),
  [DELETE_MY_USER_INFO]: (state, action) => produce(state, (draft) => {
    draft.userInfo = undefined;
    draft.userInfoLoaded = true;
  }),
  [UPDATE_USER_MEMBERSHIP]: (state, action) => produce(state, (draft) => {
    console.log('UPDATE_USER_MEMBERSHIP!', action.payload);
    draft.userInfo = {
      ...state.userInfo,
      membershipType: action.payload.category,
      membershipName: action.payload.name,
      membershipStartAt: action.payload.startAt,
      membershipExpireAt: action.payload.expireAt,
    };
  }),
  [SET_LAST_LOACTION]: (state, action) => produce(state, (draft) => {
    draft.lastLocation = action.payload.toString();
  }),
  ...pender({
    type: GET_NOTICE,
    onSuccess: (state, action) => {
      const { notices } = action.payload.data;
      return produce(state, (draft) => {
        draft.userNotice = notices;
      });
    },
  }),
  ...pender({
    type: DELETE_NOTICE,
    onSuccess: (state, action) => produce(state, (draft) => {
      const noticeId = action.meta[0];
      const userNotice = state.userNotice.filter((notice) => notice._id !== noticeId);
      draft.userNotice = userNotice;
    }),
  }),
  [SET_USER_LANGUAGE]: (state, action) => produce(state, (draft) => {
    draft.userLanguage = action.payload;
  }),

}, initialState);
