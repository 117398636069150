import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import './boot';
import { axiosSettings } from './utils/authorization';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyAiWU4WDz2vG7NAMMYx2lyJkXck1mgedek',
//   authDomain: 'ploooto.firebaseapp.com',
//   projectId: 'ploooto',
//   storageBucket: 'ploooto.appspot.com',
//   messagingSenderId: '116403345427',
//   appId: '1:116403345427:web:2c7c0704b71cb6ce0847e6',
//   measurementId: 'G-TK6C2T5ZW3',
// };
//
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

axiosSettings();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
