import { useWindowSize } from 'react-use';

const MediaView = ({ minWidth, maxWidth, children }) => {
  const { width, height } = useWindowSize();

  if (minWidth) {
    if (width < minWidth) return null;
  }

  if (maxWidth) {
    if (width > maxWidth) return null;
  }

  return children;
};

export default MediaView;
